import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    site: "shared",
    session_id: null,
    user: { isAuthenticated: false },
    count: 0,
    loading: false,
    creating: false,
    authenticated: false,
    bus: new Vue(),
    guestlist_cart: [],
  },
  mutations: {},
  actions: {},
});
